import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import LazyLoad, { forceCheck } from "react-lazyload";
import '../../src/style.css'; // Import the CSS file
import { Button } from "react-bootstrap";
import { RiDownloadLine } from "react-icons/ri";

// Function to import all images from a folder
function importAll(r) {
    return r.keys().map(r);
}

// Import images from specific folders
const client1Images = importAll(require.context("../Assets/Clients/Client1", false, /\.(png|jpe?g|svg)$/));
const client2Images = importAll(require.context("../Assets/Clients/Client2", false, /\.(png|jpe?g|svg)$/));

const projectData = {
    "client-1": client1Images,
    "client-2": client2Images,
};

function ProjectDetail() {
    const { id } = useParams();
    const images = projectData[id] || [];
    const [show, setShow] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleClose = () => setShow(false);
    const handleShow = (index) => {
        setCurrentIndex(index);
        setShow(true);
        setTimeout(forceCheck, 100); // Manually trigger lazy load check
    };

    const handleSelect = (selectedIndex) => {
        setCurrentIndex(selectedIndex);
        setTimeout(forceCheck, 100); // Manually trigger lazy load check
    };

    const handleDownload = (event) => {
        event.stopPropagation();
        const zip = new JSZip();
        images.forEach((img, index) => {
            const imgName = `image${index + 1}.jpg`;
            zip.file(imgName, fetch(img).then(res => res.blob()));
        });
        zip.generateAsync({ type: "blob" }).then(content => {
            saveAs(content, `zdjecia.zip`);
        });
    };

    return (
        <div className="project-detail-container" style={{ backgroundColor: "#24252A" }}>
            <h1>Project: {id}</h1>
            <h2 className="section-heading">Twoje Zdjęcia</h2>
            <Card className="project-card-view project-card" style={{ width: "25rem", margin: "10px" }} onClick={() => handleShow(0)}>
                <LazyLoad height={200} offset={100} once>
                    <img src={images[0]} alt="main-img" className="card-img-top" />
                </LazyLoad>
                <Card.Body>
                    <Button variant="primary" onClick={handleDownload} style={{ marginTop: "100px" }}>
                        <RiDownloadLine /> &nbsp; Pobierz
                    </Button>
                </Card.Body>
            </Card>

            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Zdjęcie</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Carousel activeIndex={currentIndex} onSelect={handleSelect}>
                        {images.map((img, index) => (
                            <Carousel.Item key={index}>
                                <LazyLoad height={200} offset={100} once>
                                    <img
                                        className="d-block w-100"
                                        src={img}
                                        alt={`Slide ${index}`}
                                    />
                                </LazyLoad>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default ProjectDetail;