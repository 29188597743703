import React, { useState } from "react";
import { Container, Row, Col, Modal, Carousel } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import LazyLoad, { forceCheck } from "react-lazyload";
import '../../../src/style.css'; // Import the CSS file

// Function to import all images from a folder
function importAll(r) {
  return r.keys().map(r);
}

// Import images from specific folders
const portfolio1Images = importAll(require.context("../../Assets/Portfolio/Portfolio1", false, /\.(png|jpe?g|svg)$/));
const portfolio2Images = importAll(require.context("../../Assets/Portfolio/Portfolio2", false, /\.(png|jpe?g|svg)$/));
const portfolio3Images = importAll(require.context("../../Assets/Portfolio/Portfolio3", false, /\.(png|jpe?g|svg)$/));
const portfolio4Images = importAll(require.context("../../Assets/Portfolio/Portfolio4", false, /\.(png|jpe?g|svg)$/));

const projectData = [
  { name: "Albatros Szufnarowa vs Czarni Czudec", images: portfolio1Images },
  { name: "Piknik rodzinny, Restauracja Bellarosaa", images: portfolio2Images },
  { name: "Kufel, Restauracja Bellarosa", images: portfolio3Images },
  { name: "Pokaz Motocrossowy Kamil Mucha", images: portfolio4Images }
];

const ProjectRow = ({ project, handleShow }) => (
  <Col md={4} className="project-card">
    <ProjectCard
      imgPath={project.images[0]} // Pass the image URL directly
      title={<span className="default-font">{project.name}</span>}
      imgStyle={{ pointerEvents: 'none', userSelect: 'none' }}
      onClick={() => handleShow(project.images, 0)}
    />
  </Col>
);

function Projects() {
  const [show, setShow] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentImages, setCurrentImages] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = (images, index) => {
    setCurrentImages(images);
    setCurrentIndex(index);
    setShow(true);
    setTimeout(forceCheck, 100); // Manually trigger lazy load check
  };

  return (
    <Container fluid className="project-section" style={{ backgroundColor: "#24252A", overflow: "hidden" }}>
      <Particle />
      <Container>
        <h1 className="project-heading">
          Moje Ostatnie Prace
        </h1>
        <p style={{ color: "white" }}></p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {projectData.map((project, index) => (
            <ProjectRow key={index} project={project} handleShow={handleShow} />
          ))}
        </Row>
      </Container>

      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Galeria</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel
            activeIndex={currentIndex}
            onSelect={(selectedIndex) => {
              setCurrentIndex(selectedIndex);
              setTimeout(forceCheck, 100); // Manually trigger lazy load check
            }}
          >
            {currentImages.map((img, index) => (
              <Carousel.Item key={index}>
                <LazyLoad height={200} offset={100}>
                  <img
                    className="d-block w-100"
                    src={img}
                    alt={`Slide ${index}`}
                    draggable="false" // Disable dragging
                  />
                </LazyLoad>
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default Projects;