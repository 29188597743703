import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/logo.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillFacebook,
  AiFillInstagram,
} from "react-icons/ai";

function Home2() {
  useEffect(() => {
    const body = document.body;
    // Reset animation
    body.classList.remove('background-animation'); // Remove class
    void body.offsetWidth; // Trigger reflow
    body.classList.add('background-animation'); // Add class again

    // Cleanup
    return () => {
      body.classList.remove('background-animation');
    };
  }, []);

  return (
    <div className="home2-container">
      <Container fluid className="home-about-section home2-content" id="about">
        <Container>
          <Row>
            <Col md={8} className="home-about-description">
              <h1 style={{ fontSize: "1.5em", color: 'white' }}>
              </h1>
              <p className="home-about-body">
                <i>
                  <b className="purple">{" "}</b>
                </i>
                <br />
                <br />
                <i>
                  <b className="purple">{" "}</b>
                </i>
                <i>
                  <b className="purple"></b>
                </i>
              </p>
            </Col>
            <Col md={4} className="myAvtar">
              <Tilt>
                <img src={myImg} className="img-fluid" alt="avatar" />
              </Tilt>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="home-about-social">
              <h1 className="blue"></h1>
              <p style={{ fontSize: "2em", color: "#ffffff" }}>Moje SocialMedia</p>
              <ul className="home-about-social-links">
                <li className="social-icons">
                  <a
                    href="https://www.instagram.com/foto_.luki/"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour home-social-icons"
                  >
                    <AiFillInstagram />
                  </a>
                </li>
                <li className="social-icons">
                  <a
                    href="https://www.facebook.com/share/tibNggiQbh84k4z5/?mibextid=LQQJ4d"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour home-social-icons"
                  >
                    <AiFillFacebook />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

export default Home2;