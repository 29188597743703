import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import poweredLogo from "../Assets/M4CODE.png"; // Importowanie loga
import logo from "../Assets/logo.png"; // Importowanie loga

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Row className="d-flex align-items-center">
        <Col md="4" className="footer-copywright d-flex align-items-center">
          <h3>Copyright © {year} Łukasz Zagórski</h3>
        </Col>

        {/* Środkowa kolumna - grafika */}
        <Col md="4" className="footer-center d-flex justify-content-center align-items-center">
          <img src={logo} alt="Center Logo" style={{ maxWidth: "150px" }} />
        </Col>

        {/* Prawa kolumna - Strona wykonana przez i logo w jednej linii */}
        <Col md="4" className="footer-right d-flex justify-content-end align-items-center">
          <strong style={{ color: 'gray', fontWeight: 'bold', fontSize: '15px', marginRight: '10px' }}>Strona wykonana przez:</strong>
          <a href="https://m4code.pl" target="_blank" rel="noopener noreferrer">
            <img src={poweredLogo} alt="Powered by Logo" className="powered-logo" />
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;