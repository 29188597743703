import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button, Modal, Form } from "react-bootstrap";
import { RiLockPasswordLine } from "react-icons/ri";

function ClientCard({ imgPath, title, password, id }) {
    const [show, setShow] = useState(false);
    const [inputPassword, setInputPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handlePasswordSubmit = () => {
        if (inputPassword === password) {
            setError("");
            handleClose();
            navigate(`/project/${id}`);
        } else {
            setError("Nieprawidłowe hasło. Spróbuj ponownie.");
        }
    };

    return (
        <>
            <Card className="project-card-view">
                <Card.Img variant="top" src={imgPath} alt="card-img" />
                <Card.Body>
                    <Card.Title><strong className="purple">{title}</strong></Card.Title>
                    <Button variant="primary" onClick={handleShow} style={{ marginLeft: "10px" }}>
                        <RiLockPasswordLine /> &nbsp; Wpisz hasło
                    </Button>
                </Card.Body>
            </Card>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Hasło</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formPassword">
                            <Form.Label>Hasło</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Wpisz hasło"
                                value={inputPassword}
                                onChange={(e) => setInputPassword(e.target.value)}
                            />
                            {error && <Form.Text className="text-danger">{error}</Form.Text>}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Zamknij
                    </Button>
                    <Button variant="primary" onClick={handlePasswordSubmit}>
                        Akceptuj
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ClientCard;