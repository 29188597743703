import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal, Carousel } from "react-bootstrap";
import ProjectCard from "./EventsCards";
import Particle from "../Particle";
import LazyLoad, { forceCheck } from "react-lazyload";
import '../../../src/style.css'; // Import the CSS file

function importAll(r) {
    return r.keys().map(r);
}

const eventsData = {
    "Event1": {
        "name": "Wydarzenie Huta Gogołowska Sprint Enduro 22.09.2024",
        "Grupa 1": importAll(require.context("../../Assets/Events/Event1/Grupa-1/", false, /\.(png|jpe?g|svg)$/)),
        "Grupa 2": importAll(require.context("../../Assets/Events/Event1/Grupa-2/", false, /\.(png|jpe?g|svg)$/))
    },
};

function Projects() {
    const [show, setShow] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentImages, setCurrentImages] = useState([]);
    const [currentImageId, setCurrentImageId] = useState(null);
    const [selectedGroups, setSelectedGroups] = useState({});
    const [projectImages, setProjectImages] = useState({});

    useEffect(() => {
        const loadImages = () => {
            const images = {};
            Object.keys(eventsData).forEach(event => {
                images[event] = {};
                Object.keys(eventsData[event]).forEach(group => {
                    if (group !== "name") {
                        images[event][group] = eventsData[event][group].map(src => ({
                            src,
                            id: generateRandomId()
                        }));
                    }
                });
            });
            setProjectImages(images);
        };

        loadImages();
    }, []);

    const generateRandomId = () => Math.floor(Math.random() * 1e18).toString();

    const handleClose = () => setShow(false);
    const handleShow = (projectIndex, event, group) => {
        const images = projectImages[event][group];
        setCurrentImages(images);
        setCurrentIndex(0);
        setCurrentImageId(images[0].id);
        setShow(true);
        setTimeout(forceCheck, 100); // Manually trigger lazy load check
    };

    const handleGroupChange = (projectIndex, group) => {
        setSelectedGroups(prevState => ({ ...prevState, [projectIndex]: group }));
    };

    return (
        <Container fluid className="project-section" style={{ backgroundColor: "#24252A" }}>
            <Particle />
            <Container>
                <h1 className="project-heading">
                    Moje Ostatnie Wydarzenia
                </h1>
                <p style={{ color: "white" }}></p>
                <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                    {Object.keys(projectImages).length > 0 && Object.keys(projectImages).map((event, projectIndex) => (
                        <Col md={4} className="project-card" key={projectIndex}>
                            <ProjectCard
                                imgPath={projectImages[event][selectedGroups[projectIndex] || "Grupa 1"][0].src}
                                title={<span className="default-font">{eventsData[event].name}</span>}
                                onClick={() => handleShow(projectIndex, event, selectedGroups[projectIndex] || "Grupa 1")}
                                onGroupChange={(group) => handleGroupChange(projectIndex, group)}
                                selectedGroup={selectedGroups[projectIndex] || "Grupa 1"}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>

            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Galeria | Id Zdjecia: <span className="selectable-id">{currentImageId}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Carousel activeIndex={currentIndex} onSelect={(selectedIndex) => {
                        setCurrentIndex(selectedIndex);
                        setCurrentImageId(currentImages[selectedIndex].id);
                        setTimeout(forceCheck, 100); // Manually trigger lazy load check
                    }}>
                        {currentImages.map((img, index) => (
                            <Carousel.Item key={index}>
                                <LazyLoad height={663} offset={100}>
                                    <img
                                        className="d-block w-100"
                                        src={img.src}
                                        alt={`Slide ${index}`}
                                        draggable="false" // Disable dragging
                                    />
                                </LazyLoad>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Modal.Body>
            </Modal>
        </Container>
    );
}

export default Projects;