import React from "react";
import { Card } from "react-bootstrap";

function ProjectCard({ imgPath, title, imgStyle, onClick }) {
  return (
    <Card className="project-card-view" onClick={onClick}>
      <Card.Img variant="top" src={imgPath} style={imgStyle} />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
      </Card.Body>
    </Card>
  );
}

export default ProjectCard;