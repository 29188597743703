import React, { useState } from "react";
import { Container, Row, Col, Modal, Carousel } from "react-bootstrap";
import ProjectCard from "./ClientCards";
import Particle from "../Particle";
import LazyLoad, { forceCheck } from "react-lazyload";
import '../../../src/style.css'; // Import the CSS file

// Function to import all images from a folder
function importAll(r) {
    return r.keys().map(r);
}

// Import images from specific folders
const client1Images = importAll(require.context("../../Assets/Clients/Client1", false, /\.(png|jpe?g|svg)$/));
const client2Images = importAll(require.context("../../Assets/Clients/Client2", false, /\.(png|jpe?g|svg)$/));

const projectData = [
    { id: "client-1", name: "Zabezpieczone: Piknik rodzinny, Restauracja Bellarosa", images: client1Images, password: "bellarosa1" },
    { id: "client-2", name: "Pokaz Motocrossowy Kamil Mucha", images: client2Images, password: "kamilmucha1" },
];

function Projects() {
    const [show, setShow] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentImages, setCurrentImages] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = (images, index) => {
        setCurrentImages(images);
        setCurrentIndex(index);
        setShow(true);
        setTimeout(forceCheck, 100); // Manually trigger lazy load check
    };

    return (
        <Container fluid className="project-section" style={{ backgroundColor: "#24252A" }}>
            <Particle />
            <Container>
                <h1 className="project-heading" style={{ fontFamily: 'serif' }}>
                    Strefa Klienta
                </h1>
                <p style={{ color: "white" }}></p>
                <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                    {projectData.map((project, projectIndex) => (
                        <Col md={4} className="project-card" key={projectIndex}>
                            <LazyLoad height={200} offset={100} once>
                                <ProjectCard
                                    imgPath={project.images[0]} // Pass the image URL directly
                                    title={project.name}
                                    password={project.password}
                                    id={project.id}
                                    onClick={() => handleShow(project.images, 0)}
                                />
                            </LazyLoad>
                        </Col>
                    ))}
                </Row>
            </Container>

            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Galeria</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Carousel
                        activeIndex={currentIndex}
                        onSelect={(selectedIndex) => {
                            setCurrentIndex(selectedIndex);
                            setTimeout(forceCheck, 100); // Manually trigger lazy load check
                        }}
                    >
                        {currentImages.map((img, index) => (
                            <Carousel.Item key={index}>
                                <LazyLoad height={200} offset={100}>
                                    <img
                                        className="d-block w-100"
                                        src={img}
                                        alt={`Slide ${index}`}
                                        draggable="false" // Disable dragging
                                    />
                                </LazyLoad>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Modal.Body>
            </Modal>
        </Container>
    );
}

export default Projects;