import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

function ProjectCard({ imgPath, title, onClick, onGroupChange, selectedGroup }) {
    return (
        <Card className="project-card-view">
            <Card.Img variant="top" src={imgPath} alt="card-img" onClick={onClick} />
            <Card.Body>
                <Card.Title><strong className="purple">{title} </strong></Card.Title>
                <Button
                    variant={selectedGroup === "Grupa 1" ? "primary" : "light"}
                    onClick={() => onGroupChange("Grupa 1")}
                    style={{ marginRight: "10px" }}
                >
                    Grupa 1
                </Button>
                <Button
                    variant={selectedGroup === "Grupa 2" ? "primary" : "light"}
                    onClick={() => onGroupChange("Grupa 2")}
                >
                    Grupa 2
                </Button>
            </Card.Body>
        </Card>
    );
}

export default ProjectCard;