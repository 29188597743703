import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify", color: "#ffffff" }}>
            Cześć jestem Łukasz
            <br />
            <br /> Interesuje się fotografią od małego, teraz uważam Fotografię jako moja ulubiona pasja przez, którą na mojej twarzy pojawia się uśmiech.
            <br />
            <br /> Jestem w stanie wykonać każdą sesję jaką tylko klient zapragnie.
            <br />
            <br /> Pracuję na sprzęcie od firmy Sony.
            <br />
          </p>
          <ul>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
